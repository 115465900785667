.alert{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    color: white;
    z-index: 9999;
}
@media screen and (max-width:768px){
    .alert-box{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 90%;
        background-color: black;
        border: 1px solid wheat;
    }
}

@media screen and (min-width:769px){
    .alert-box{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 500px;
        background-color: black;
        border: 1px solid wheat;
    }
}

.alert-box-title{
    font-size: 20px;
}

.alert-box-img{
  width: 100%;
}
.alert-box-content{
    font-size: 14px;
    text-align: left;
}

.alert-box-content-span{
  font-size: 14px;
  text-align: center;
  width: 100%;
  line-height: 40px;
}

.notify{
    width: 100%;
    padding-top: 90px;
    padding-bottom: 20px;
    background-color: black;
    color: white;
    font-size: 12px;
}
.notify-title{

}
.notify-content{

}

@media screen and (max-width:768px){
    .top-head{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background: #fff;
        width: 90%;
        height: 70px;
        backdrop-filter: blur(3px);
        background-color: rgba(0, 0, 0, 1);
        display: flex;
        color: white;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
    }

    .top-head-left{
        font-size: 20px;
        font-weight: bold;
    }
    
    .top-head-right{
        display: none;
    }
    .top-head-right-iphone{
        display: block;
    }
}

@media screen and (min-width:769px){
    .top-head{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background: #fff;
        width: 90%;
        height: 70px;
        backdrop-filter: blur(3px);
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        color: white;
        justify-content: space-between;
        align-items: center;
        padding: 0 5%;
    }
    
    .top-head-left{
        font-size: 20px;
        font-weight: bold;
    }
    
    .top-head-right{
        font-size: 14px;
    }
    
    .top-head-right a{
        float: left;
        margin: 0 20px;
        color: white;
        text-decoration: none;
    }

    .top-head-right-iphone{
        display: none;
    }
}

@media screen and (max-width:768px){
    .top-top{
        width: 100%;
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .top-top-img{
        width: 100%;
        max-width: 100%;
    }

    .top-top-title{
        position: absolute;
        bottom: 0px;
        width: 100%;
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        background: linear-gradient(rgba(0,0,0,0), #191919);
    }
}

@media screen and (min-width:769px){
    .top-top{
        width: 100%;
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .top-top-img{
        width: 100%;
        max-width: 100%;
    }

    .top-top-title{
        position: absolute;
        bottom: 0px;
        width: 100%;
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        background: linear-gradient(rgba(0,0,0,0), #191919);
    }
}

.top-top-title-1{
    font-size: 26px;
    color: #a59e62;
    width: 100%;
    text-align: center;
}

.top-top-title-2{
    color: #cdcdcd;
    width: 100%;
    text-align: center;
}

@media screen and (max-width:768px){
    .top-guide{
        background-color: black;
        width: 100%;
        margin-top: 40px;
        padding-top:60px;
        padding-bottom: 60px;
    }
}

@media screen and (min-width:769px){
    .top-guide{
        background-color: black;
        width: 100%;
        margin-top: 100px;
        padding-top:60px;
        padding-bottom: 60px;
    }
}

.top-guide-box{
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width:768px){
    .top-guide-text{
        width: 100%;
        color: #cdcdcd;
    }

    .top-guide-text-title{
        padding: 0 5%;
        color: #777;
        font-size: 20px;
    }
    
    .top-guide-text-content-1{
        padding: 0 5%;
        font-size: 24px;
        margin-top: 20px;
    }
    
    .top-guide-text-content-2{
        padding: 0 5%;
        font-size: 16px;
        margin-top: 20px;
        line-height: 2;
        font-family: "Noto Sans JP", sans-serif;
        font-optical-sizing: auto;
        font-weight: 255;
        font-style: normal;
    }
    

    .top-guide-img{
        width: 100%;
        position: relative;
        margin-bottom: 50px;
    }
    
    .top-guide-img-1{
        width: 60%;
        margin:auto;
        z-index: 2;
        position: relative;
    }
    
}

@media screen and (min-width:769px){
    .top-guide-text{
        width: 65%;
        float: left;
        color: #cdcdcd;
    }

    .top-guide-text-title{
        padding: 0 10%;
        color: #777;
        font-size: 20px;
    }
    
    .top-guide-text-content-1{
        padding: 0 10%;
        font-size: 26px;
        margin-top: 20px;
    }
    
    .top-guide-text-content-2{
        padding: 0 10%;
        font-size: 16px;
        margin-top: 20px;
        line-height: 2;
        font-family: "Noto Sans JP", sans-serif;
        font-optical-sizing: auto;
        font-weight: 255;
        font-style: normal;
    }
    

    .top-guide-img{
        width: 33%;
        float: left;
        position: relative;
    }
    
    .top-guide-img-1{
        width: 100%;
        z-index: 2;
        position: relative;
    }
    
    .top-guide-img::before {
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid #383838;
        position: absolute;
        z-index: 0;
        left: -12px;
        bottom: -12px;
        display: block;
    }
    
}

.top-room{
    background-color: black;
    width: 100%;
    color: #cdcdcd;
}

.top-room-title{
    padding-top: 50px;
    color: #777;
    font-size: 20px;
}

@media screen and (max-width:768px){
    .top-room-content-1{
        width: 90%;
        max-width: 1080px;
        padding: 0 5%;
        font-size: 24px;
        margin: 20px auto;
    }
}

@media screen and (min-width:769px){
    .top-room-content-1{
        width: 90%;
        max-width: 1080px;
        padding: 0 5%;
        font-size: 26px;
        margin: 20px auto;
    }
}

.top-room-content-2{
    width: 90%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    margin-top: 20px;
    line-height: 2;
    padding: 0 5%;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 255;
    font-style: normal;
}

.top-room-imgs{
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

@media screen and (max-width:768px){
    .top-room-img{
        width: 100%;
        margin-top: 10px;
        float: left;
    }

    .top-room-img2{
      width: 100%;
      margin-top: 10px;
      float: left;
    }

    .top-cast-content-1{
        width: 100%;
        font-size: 24px;
        margin: 20px 0;
    }
}

@media screen and (min-width:769px){
    .top-room-img{
        width: 48%;
        margin: 1%;
        float: left;
    }

    .top-room-img2{
      width: 31.33%;
      margin: 1%;
      float: left;
    }

    .top-cast-content-1{
        width: 100%;
        font-size: 26px;
        margin: 20px 0;
    }
}

.top-cast{
    width: 100%;
    color: #cdcdcd;
    margin-top: 20px;
}

.top-cast-box{
    width: 90%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
}

.top-cast-title{
    width: 100%;
    color: #777;
    font-size: 20px;
}

.top-cast-content-2{
    width: 100%;
    margin-bottom: 80px;
    line-height: 2;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 255;
    font-style: normal;
}

.top-cast-imgs{
    width: 100%;
}

.top-cast-img{
    width: 32.33%;
    margin: 0.5%;
    float: left;
}

.top-charge{
    width: 100%;
    color: #cdcdcd;
    background-color: black;
    padding-top: 80px;
    padding-bottom: 80px;
}

.top-charge-title{
    width: 100%;
    color: #777;
    font-size: 20px;
}

@media screen and (max-width:768px){
    .top-charge-content-1{
        width: 100%;
        font-size: 24px;
        margin: 20px 0;
    }
}

@media screen and (min-width:769px){
    .top-charge-content-1{
        width: 100%;
        font-size: 26px;
        margin: 20px 0;
    }
}

.top-charge-content-2{
    width: 90%;
    line-height: 2;
    padding: 0 5%;
}

.top-charge-table{
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    font-size: 14px;
    margin-bottom: 50px;
}

.top-charge-table-title{
    border: 1px solid #242424;
    display: flex;
    padding: 10px 40px;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 255;
    font-style: normal;
}

.top-charge-table-list{
    display: flex;
    padding: 10px 40px;
    position: relative;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 255;
    font-style: normal;
}

.top-charge-table-list::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    z-index: 1;
    border-radius: inherit; /*important*/
    background: linear-gradient(rgba(0,0,0,0) ,#242424, rgba(0,0,0,0));
}

.top-charge-table-left{
    width: 50%;
}

.top-charge-table-right{
    width: 50%;
}

.top-wanted{
    width: 100%;
    color: #cdcdcd;
    padding: 60px 0;
}

.top-wanted-title{
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    color: #777;
    font-size: 20px;
}

@media screen and (max-width:768px){
    .top-wanted-content{
        width: 100%;
        max-width: 1080px;
        font-size: 24px;
        margin: 20px auto;
    }
}

@media screen and (min-width:769px){
    .top-wanted-content{
        width: 100%;
        max-width: 1080px;
        font-size: 26px;
        margin: 20px auto;
    }
}

.top-wanted-box{
    width: 90%;
    line-height: 2;
    padding: 0 5%;
}

.top-information{
    width: 100%;
    background: black;
    color: #cdcdcd;
    padding: 60px 0;
}

.top-information-title{
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    color: #777;
    font-size: 20px;
}

@media screen and (max-width:768px){
    .top-information-content{
        width: 100%;
        max-width: 1080px;
        font-size: 24px;
        margin: 20px auto;
    }
}

@media screen and (min-width:769px){
    .top-information-content{
        width: 100%;
        max-width: 1080px;
        font-size: 26px;
        margin: 20px auto;
    }
}

.top-information-box{
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    line-height: 2;
    margin-top: 50px;
}

@media screen and (max-width:768px){
    .top-information-left{
        width: 90%;
        padding: 5%;
        font-family: "Noto Sans JP", sans-serif;
        font-optical-sizing: auto;
        font-weight: 255;
        font-style: normal;
    }

    .top-information-left-img{
        width: 70%;
    }

    .top-information-right{
        width: 90%;
        padding: 5%;
    }
}

@media screen and (min-width:769px){
    .top-information-left{
        width: 50%;
        float: left;
        text-align: left;
        padding-left: 5%;
        font-family: "Noto Sans JP", sans-serif;
        font-optical-sizing: auto;
        font-weight: 255;
        font-style: normal;
    }

    .top-information-left-img{
        width: 50%;
    }
    
    .top-information-right{
        width: 40%;
        float: left;
        padding-right: 5%;
    }
}

.top-information-right-map{
    width: 100%;
    height: 300px;
    border: none;
}

.top-foot{
    width: 100%;
    color: #cdcdcd;
    font-size: 14px;
    padding-top: 30px;
}

.top-foot-content-title{
    font-size: 18px;
    margin-bottom: 20px;
}

.top-foot-content-detail{
    width: 100%;
    font-size: 12px;
    padding-bottom: 30px;
    line-height: 2;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 255;
    font-style: normal;
}

.top-foot-content-rr{
    font-size: 14px;
    background-color: black;
    padding: 30px 0;
}

.header-menu{
    padding:16px 0px 16px 80px
}

.header-menu-slotline{
    height: 2px;
    width: 18px;
    background-color: white;
    margin-top: 4px;
    margin-bottom: 4px;
    transition: all 0.3s;
}

.header-menu-slotline2{
    height: 2px;
    width: 18px;
    background-color: white;
    margin-top: 4px;
    margin-bottom: 4px;
    transform: rotate(45deg) translate(0px, 4px);
    transition: all 0.3s;
}

.header-menu-slotline3{
    height: 2px;
    width: 18px;
    background-color: white;
    margin-top: 4px;
    margin-bottom: 4px;
    transform: rotate(-45deg) translate(0px,-4px);
    transition: all 0.3s;
}

.header-menu-slotline4{
    display: none;
}

.header-menu-lists{
    position: fixed;
    top:0px;
    height: 100vh;
    width: 100%;
    background-color: black;
    color: white;
    z-index: 100;
    transform: translate(0,-100%);
    transition: all 0.3s;
    padding-top: 50px;
    padding-top: 100px;
}

.header-menu-list{
    height: 50px;
    font-size: 14px;
    border-bottom: 1px solid #353535;
    color: white;
    display: block;
    text-align: left;
    text-decoration: none;
    font-size: 18px;
    line-height: 50px;
    padding-left: 5%;
}

.line{
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: #777;
}